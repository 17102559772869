<template>
  <div style="display: flex">
    <span
      v-for="e of options"
      :key="e[replaceFields.value]"
      :class="[
        'box',
        {
          select: value == e[replaceFields.value],
          boxShadow: value == e[replaceFields.value],
        },
      ]"
      style=""
      @click="$emit('set:value', e[replaceFields.value])"
    >
      {{ e[replaceFields.lable] }}</span
    >
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: [Number, String],
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
    replaceFields: {
      type: Object,
      default: () => ({
        lable: 'lable',
        value: 'value',
      }),
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
  font-size: 14px;
  font-weight: 400;
  color: #1f9bfb;
  background: rgba(6, 58, 111, 0.1);
  border: 1px solid #2cadff;
  padding: 0px 15px;
  margin-right: 6px;
  cursor: pointer;
}
.select {
  color: #e9faff;
}
</style>
